

function Privacy() {

    return (
        <>
        Privacy
        </>
    )
}

export default Privacy
import './BrowserWarning.scss'
import chromeIcon from '../images/chrome_icon.png'
import firefoxIcon from '../images/firefox_icon.png'
import edgeIcon from '../images/edge_icon.png'

function BrowserWarning() {
    return (
        <div className="browser-warning-container">
            <div className="browser-warning-title">
                It looks like your web browser is out of date
            </div>
            <div className="browser-warning-body">
                Update your browser for more security, speed and the best experience.
            </div>
            <div className="browser-warning-icons">
                <a href="https://www.google.com/chrome/"><img src={chromeIcon} className="browser-warning-icon" alt="Chrome"/></a>
                <a href="https://www.mozilla.org/en-US/firefox/new/"><img src={firefoxIcon} className="browser-warning-icon" alt="Firefox"/></a>
                <a href="https://www.microsoft.com/en-us/edge"><img src={edgeIcon} className="browser-warning-icon" alt="Edge"/></a>
            </div>
        </div>
    )
}

export default BrowserWarning
import FirstPage from './Pages/First Page/FirstPage';
import MarketSignals from './Pages/Market Signals/MarketSignals';
import USAStocks from './Pages/USA Stocks/USAStocks';
import IndicatorLevels from './Pages/Indicator Levels/IndicatorLevels';
import DarkMode from './Pages/Dark Mode/DarkMode';
import LastPage from './Pages/Last Page/LastPage';


function Home() {
    return (
        <>
            <FirstPage/>
            <MarketSignals/>
            <USAStocks/>
            <IndicatorLevels/>
            <DarkMode/>
            <LastPage/>
        </>
    )
}

export default Home
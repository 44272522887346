import { useMediaQuery } from "react-responsive";

function Page(props) {
    const isDefault = useMediaQuery({ minWidth: 768 })

    return (
    <div className={"App-page " + props.className + (isDefault ? '' : (' ' + props.className + '-mobile'))} style={{
        backgroundColor: props.bgColour, 
        backgroundImage: props.bgImage ? `url(${props.bgImage})` : null,
        ...props.style
    }}>
        {props.children}
    </div>
    );
  }
  
  export default Page;
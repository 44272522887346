import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Error';
import Home from './Home/Home';
import ContactUs from './ContactUs';
import Support from './Support';
import Terms from './Terms';
import Privacy from './Privacy';
import { isIE } from 'react-device-detect'
import BrowserWarning from './Browser Warning/BrowserWarning';
import Feedback from './feedback';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <Error/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "contactus",
        element: <ContactUs/>
      },
      {
        path: "support",
        element: <Support/>
      },
      {
        path: "terms",
        element: <Terms/>
      },
      {
        path: "privacy",
        element: <Privacy/>
      },
      {
        path: "feedback",
        element: <Feedback/>
      }
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));

if (isIE) {
  root.render(
    <BrowserWarning/>
  );
} else {
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

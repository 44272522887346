import './DarkMode.scss'
import { useEffect, useRef, useState } from 'react'
import Switch from 'react-ios-switch/lib/Switch'
import darkModePhonesNoShadow from '../../../images/dark_mode_phones_no_shadow.png'
import lightModePhonesNoShadow from '../../../images/light_mode_phones_no_shadow.png'
import darkModeShadow from '../../../images/dark_mode_shadow.png'
import Page from '../../Page'
import { AnimatePresence, motion, useInView, useMotionValue, useSpring, useTransform } from "framer-motion"
import { Divider } from '@mui/material'

function DarkMode(props) {

    const [darkMode, setDarkMode] = useState(true)
    const ref = useRef(null)
    const scrollTriggerInView = useInView(ref, {once: true})

    const timeoutLength = 8000
    const y = useMotionValue(0)
    const yAnimation = useSpring(useTransform(y, [0, 1], [100, 0]), {stiffness: 50, damping: 10, bounce: 0.1, mass: 10})
    const xAnimation = useSpring(useTransform(y, [0, 1], [-400, -300]), {stiffness: 50, damping: 10, bounce: 0.1, mass: 10})

    useEffect(() => {
        if(scrollTriggerInView){
            y.set(1)

            let timer = setTimeout(flipSwitch, 3000);
            return () => clearTimeout(timer);
        }
    }, [scrollTriggerInView])

    const flipSwitch = () => {
        setDarkMode(currMode => !currMode)
        let timer = setTimeout(flipSwitch, timeoutLength);
        return () => clearTimeout(timer);
    }

    const DarkPhone = <motion.img 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            style={{ y: yAnimation, x: '-50%'}}
            src={darkModePhonesNoShadow}
            className="Dark-mode-phones" 
            alt="Dark mode phones"
        />

    const LightPhone = <motion.img 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        style={{ y: yAnimation, x: '-50%'}}
        src={lightModePhonesNoShadow}
        className="Dark-mode-phones" 
        alt="Dark mode phones"
    />

    return (
        <Page bgColour={"#343638"} className="dark-mode-page">
            {/* Need to preload these images so the light mode doesnt load for the first time on switch */}
            <img src={lightModePhonesNoShadow} style={{display: 'none'}}/>
            <img src={darkModePhonesNoShadow} style={{display: 'none'}}/>
            <div className="Dark-mode-light-bg"></div>
                <h2 className={"Dark-mode-title title-xlarge"}>Optimised for dark mode</h2>
                <div className="Dark-mode-switch-container">
                    <Switch
                        checked={darkMode}
                        onChange={() => setDarkMode(!darkMode)}
                        onColor='#D8783F'
                        size="large"
                    />
                </div>
                <div ref={ref}>
                    {/* Need to use two different images here so the fade in/out animations work */}
                    <AnimatePresence>
                        {darkMode && DarkPhone}
                    </AnimatePresence>
                    <AnimatePresence>
                        {!darkMode && LightPhone}
                    </AnimatePresence>
                    <motion.img
                        style={{ x: xAnimation }}
                        src={darkModeShadow}
                        className="Dark-mode-phones-shadow" 
                        alt="Dark mode phones"
                    />
                </div>
        </Page>
    )
}

export default DarkMode
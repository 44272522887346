import { Button, IconButton, TextField } from "@mui/material"
import { useState } from "react"
import ReactImageUploading from "react-images-uploading";
import CancelIcon from '@mui/icons-material/Cancel';

function Feedback() {
    const [images, setImages] = useState([]);

    const [formValues, setFormValues] = useState(
        {
            id: '',
            name: '',
            email: '',
            feedback: '',
        }
    )

    const handleImageChange = (
        imageList,
        addUpdateIndex
      ) => {
        setImages(imageList);
      };

    const handleChange = (e) => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        console.log(formValues)
    }

    return (
        <div className="feedback-page">
            <div className="feedback-container">
                <p className="title-large">Feedback</p>
                <form onChange={handleChange}>
                    <div className="feedback-form">
                        <input name="id" value={formValues.id} hidden/>
                        <TextField variant="filled" label="Name" name="name" value={formValues.name}/>
                        <TextField variant="filled" label="Email" name="email" value={formValues.email}/>
                        <TextField variant="filled" label="Feedback" name="feedback" value={formValues.feedback} rows={3} multiline/>
                    </div>
                </form>
                <ReactImageUploading
                    multiple
                    value={images}
                    onChange={handleImageChange}
                    maxNumber={8}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                    }) => (
                    // write your building UI
                    <div>
                        <button 
                            onClick={onImageUpload}
                            className={`feedback-images-upload-container ${imageList.length > 0 ? 'feedback-images-uploaded' : ''}`}
                            {...dragProps}
                        >
                            {imageList.length > 0 && imageList.map((image, index) => (
                                <div key={index} className="feedback-image">
                                    <div className="feedback-image-container">
                                        <img className="feedback-image" src={image.dataURL} alt="" width="100" />
                                    </div>
                                    <IconButton 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onImageRemove(index)
                                        }}
                                        className="feedback-image-delete"
                                        variant="contained"
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ))}
                            {/* {imageList.length == 0 && <div className="feedback-images-upload-instructions">
                                <div>Select images or drag here to upload</div>
                                <Button
                                    variant="contained"
                                >
                                    Select Images
                                </Button>
                            </div>} */}
                            {imageList.length == 0 && "Click or drag here to upload images"}
                        </button>
                    </div>
                    )}
                </ReactImageUploading>
                <Button className="feedback-form-submit" variant="contained" onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    )
}

export default Feedback
function getIOSversion() {
    var version = 0
    var agent = window.navigator.userAgent,
    start = agent.indexOf( 'OS ' )
    if( ( agent.indexOf( 'iPhone' ) > -1 || agent.indexOf( 'iPad' ) > -1 ) && start > -1 ){
        version = window.Number( agent.substring( start + 3, start + 6 ).replace( '_', '.' ) ) 
    }
    return version
}

export default getIOSversion
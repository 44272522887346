import './USAStocks.scss'
import { useScroll, useTransform, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import accessStocksBg from '../../../images/access_stocks_bg.jpeg'
import accessStocksBgMobile from '../../../images/access_stocks_bg_mobile.jpeg'
import Page from "../../Page"
import { useMediaQuery } from 'react-responsive'
import getIOSversion from '../../../getIOSVersion'
import { Divider } from '@mui/material'

function USAStocks() {
    const [iosVersion, ] = useState(getIOSversion())
    const ref = useRef(null)
    const isDefault = useMediaQuery({ minWidth: 768 })

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: isDefault ? ["0 1", "1 1"] : ["0 1", "0.5 1"]
    })
    
    const xAnimation = useTransform(scrollYProgress, [0, 1], [-300, 0])
    const opacityAnimation = useTransform(scrollYProgress, [0, 1], [0.1, 1])

    return (
        <Page className="usa-stocks-page" bgImage={isDefault ? accessStocksBg : accessStocksBgMobile}>
            <motion.div style={((iosVersion > 12 || iosVersion === 0) && !isDefault) ? { x: xAnimation, opacity: opacityAnimation } : {}} transition={{ type: 'spring' }} ref={ref} className="USA-stocks-text-container">
                <h2 className="title-xlarge">Access 5000+ stocks in the USA</h2>
                <p className="page-subtitle">Search and scroll by stock name and add to your watch list.</p>
                <p className="page-subtitle">View up to date data for each stock and set your indicator alerts.</p>
            </motion.div>
            <div className="indicator-level-section">
                <table style={{width: '100%'}}>
                    <tbody>
                        <tr>
                            <td><p className="title-medium">Medium Term</p></td>
                            <td rowSpan={2} className="table-divider"></td>
                            <td><p className="title-medium">Long Term</p></td>
                            <td rowSpan={2} className="table-divider"></td>
                            <td><p className="title-medium">Trend Reversal</p></td>
                        </tr>
                        <tr>
                            <td><p className="page-subtitle">50 day moving average</p></td>
                            <td><p className="page-subtitle">200 day moving average</p></td>
                            <td><p className="page-subtitle">Based on average stock price</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Page>
    )
}

export default USAStocks
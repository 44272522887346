import './LastPage.scss'
import downloadBg from '../../../images/download_bg.jpeg'
import downloadBgMobile from '../../../images/download_bg_mobile.jpeg'
import Download from "../../Download"
import Page from "../../Page"
import { useMediaQuery } from 'react-responsive'

function LastPage() {
    const isDefault = useMediaQuery({ minWidth: 768 })

    return(
        <Page className="download-page" bgImage={isDefault ? downloadBg : downloadBgMobile}>
            <div className="download-container">
                <h2 className="download-title title-large">Download the latest version of the App.</h2>
                <div className="download-links">
                    <Download/>
                </div>
            </div>
        </Page>
    )
}

export default LastPage
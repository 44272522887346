import './IndicatorLevel.scss'
import indicatorLevelMedium from '../../../images/indicator_level_medium.png'
import indicatorLevelLong from '../../../images/indicator_level_long.png'
import indicatorLevelReverse from '../../../images/indicator_level_reverse.png'
import kimiSignalsLogo from '../../../images/kimi_signals_logo.png'
import kimiNotification from '../../../images/example_notification.png'
import Level from "./Level"
import Page from '../../Page'
import { useRef, useState } from 'react'
import { motion, useInView, useScroll, useTransform } from 'framer-motion'
import getIOSversion from '../../../getIOSVersion'

function IndicatorLevels() {
    
    const [iosVersion, ] = useState(getIOSversion())
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["0 1", "0 0.3"]
    })
    const offScreen = 800
    const leftPhoneAnim = useTransform(scrollYProgress, [0, 0.5], [offScreen, 0])
    const midPhoneAnim = useTransform(scrollYProgress, [0, 0.75], [offScreen, 0])
    const rightPhoneAnim = useTransform(scrollYProgress, [0, 1], [offScreen, 0])


    const notificationTriggerRef = useRef(null)
    const notificationTriggerInView = useInView(notificationTriggerRef, {once: true})
    const notificationAnimVariants = {
        stop: { translateY: "1000px" },
        start: { translateY: "0px" },
    }

    return (
        <Page className="indicator-level-page" >
            <div className='Indicator-level-text-container'>
                <div className='Indicator-level-title-container'>
                    <h2 className="title-xlarge">Choose your <span className="Ital-text">stocks</span> and use these features</h2>
                </div>
                <div ref={ref} className="Indicator-level-phones">
                    {/* <motion.div style={(iosVersion > 12 || iosVersion === 0) ? {y: leftPhoneAnim} : {}}> */}
                        <Level
                            image={indicatorLevelMedium}
                            logo={kimiSignalsLogo}
                            title="Curate your watchlist"
                            text="Select from over 5000 US stocks to set up your trackers"
                        />
                    {/* </motion.div> */}
                    {/* <motion.div style={(iosVersion > 12 || iosVersion === 0) ? {y: midPhoneAnim} : {}}> */}
                        <Level 
                            image={indicatorLevelLong}
                            logo={kimiSignalsLogo}
                            title="Dive into the data"
                            text="View charts, prices and indicators for your stocks"
                        />
                    {/* </motion.div> */}
                    {/* <motion.div style={(iosVersion > 12 || iosVersion === 0) ? {y: rightPhoneAnim} : {}}> */}
                        <Level 
                            image={indicatorLevelReverse}
                            logo={kimiSignalsLogo}
                            title="Choose your indicators"
                            text="Select which trends to track and when to receive alerts"
                        />
                    {/* </motion.div> */}
                </div>
                <div className='example-notification-container'>
                    <motion.img 
                        animate={notificationTriggerInView ? 'start' : 'stop'}
                        variants={notificationAnimVariants}
                        transition={{ type: 'spring', duration: 1 }}
                        viewport={{ once: true }}
                        src={kimiNotification} 
                        className='example-notification-image'
                    />
                    <p className='title-small'>Now start receiving your Kimi Signal alerts</p>
                </div>
                <div ref={notificationTriggerRef} style={{position: 'absolute', top: '140vh'}}></div>
            </div>
        </Page>
    )
}

export default IndicatorLevels
import './FirstPage.scss'
import handHoldingPhone from '../../../images/hand_holding_phone.png'
import kimiSignalsLogo from '../../../images/kimi_signals_logo.png'
import Page from '../../Page'
import Download from '../../Download';

function FirstPage() {

    return (
        <Page className="first-page">
            <div className={"Stock-price-text-container"}>
                <img src={kimiSignalsLogo} className="Kimi-signals-logo" alt="logo" />
                <h1 className="title-large">Monitor <span className="Orange-text">your</span> stock prices</h1>
                <p className="page-subtitle">One simple App that monitors your stock prices so you never miss a signal.</p>
                <Download/>
            </div>
            <img src={handHoldingPhone} className={"Stock-price-phone"} alt="hand holding phone" />
        </Page>
    )
}

export default FirstPage


function Terms() {

    return (
        <>
        Terms
        </>
    )
}

export default Terms